<template>
    <layout
        :activeIndex="activeIndex"
        :activeModel.sync="activeModel"
        :topBarItems="topBarItems"
        @setIndex="setIndex"
    >
        <template #background>
            <div
                :style="
                    'background: url(' + imgUrl + ') center center / cover;'
                "
                class="position-absolute"
                style="
                    width: 100vw;
                    height: calc(100vh - 4.45rem);
                    top: -1.5rem;
                    left: -2rem;
                    background: yellow;
                "
            ></div>
        </template>
        <template #left-0>
            <b-card>
                <b-card-title>模式选择</b-card-title>
                <b-button-group class="w-100">
                    <b-button
                        @click="selected = 1"
                        :variant="
                            (selected === 1 ? '' : 'outline-') + 'success'
                        "
                    >
                        <feather-icon class="mr-50" icon="SunIcon" size="18" />
                        <span style="line-height: 18px">白天</span>
                    </b-button>
                    <b-button
                        @click="selected = 2"
                        :variant="
                            (selected === 2 ? '' : 'outline-') + 'success'
                        "
                    >
                        <feather-icon class="mr-50" icon="MoonIcon" size="18" />
                        <span style="line-height: 18px">夜晚</span>
                    </b-button>
                </b-button-group>
            </b-card>
        </template>
        <template #left-1> </template>
        <template #right-0></template>
        <template #right-1></template>
        <template #right-3>
            <panel-group :show-title="false" group-uid="8c24cf19"></panel-group>
        </template>
    </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { reactive, toRefs, watch } from "@vue/composition-api";
// import LeftMenu from "@/views/front/project-jsf/subsystems/layout/components/LeftMenu";

export default {
    name: "floodlighting",
    components: {
        Layout,
        panelGroup: () => import("@/views/panel-group"),
        // LeftMenu,
    },
    setup() {
        // 框架相关参数
        const layoutParams = reactive({
            activeIndex: 0,
            activeModel: "",
            topBarItems: [
                {
                    title: "主页",
                    // model: {path: 'JSF-Building/jsf', dbIds: [], rotate: '2687,-6337,610'}
                },
                /* { title: "模式" }, */
                /* { title: "数据分析" }, */
            ],
        });
        //左侧菜单相关参数
        const leftMenuParams = reactive({
            floors: [],
            system: true,
        });
        // 左侧菜单选中事件
        const leftMenuSelect = (event) => {
            console.log("leftMenuSelectEvent", event);
            // activeModel.value = event.model
        };

        const setIndex = (e) => {
            layoutParams.activeIndex = e;
        };

        const data = reactive({
            selected: 1,
            imgUrl: "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/projects/72_bg_1.png",
        });

        watch(
            () => data.selected,
            (newVal) => {
                // layoutParams.topBarItems[0].model.mode = newVal
                if (newVal === 1) {
                    data.imgUrl =
                        "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/projects/72_bg_1.png";
                } else {
                    data.imgUrl =
                        "https://cabrenergy-v2-1255710621.cos.ap-beijing.myqcloud.com/projects/72_bg_2.png";
                }
            }
        );

        return {
            ...toRefs(layoutParams),
            ...toRefs(leftMenuParams),
            leftMenuSelect,
            setIndex,
            ...toRefs(data),
        };
    },
};
</script>

<style lang="scss" scoped></style>
